<template>
  <div class="h-full">
    <div
      v-if="new Date() < new Date('2025-02-15') && !hidePricingUpBanner"
      class="flex justify-between items-center gap-6 2md:gap-2 w-full px-4 2md:px-6 py-2 transition-colors bg-primary text-white-smoke"
    >
      <router-link
        to="/settings"
        class="flex flex-row items-center gap-2 w-full"
        @click="handleHidePricingUpBanner"
      >
        <FontAwesomeIcon
          :icon="faChartLineUp"
          class="mt-px mr-1"
        />
        <p class="m-0 text-xs w-full 2md:w-2/3">{{ $t('home.pricingEvolution') }}</p>
      </router-link>
      <span
        class="bg-white-smoke rounded-md px-1.5 py-0.5 text-xs cursor-pointer"
        @click="handleHidePricingUpBanner"
      >
        <FontAwesomeIcon
          :icon="faClose"
          class="text-primary"
        />
      </span>
    </div>
    <div
      v-if="new Date() < new Date('2024-02-01') && !hideCgvBanner"
      to="/settings"
      class="flex justify-between items-center gap-6 2md:gap-2 w-full px-4 2md:px-6 py-2 transition-colors bg-primary text-white-smoke"
    >
      <div class="flex flex-row justify-center items-center gap-2">
        <FontAwesomeIcon
          :icon="faScaleBalanced"
          class="mt-px mr-1"
        />
        <p class="m-0 text-xs w-full 2md:w-2/3">
          {{ $t('home.legalDocs.edited') }} {{ $t('home.legalDocs.our') }}
          <a
            :href="`https://go.skyloud.net/cgv-infogerance${locale ? `-${locale}` : ''}`"
            style="text-decoration: underline; font-weight: 700"
            target="_blank"
            rel="noopener noreferrer"
            @click="handleCustomerReading('cgvInfogerance')"
          >
            {{ $t('home.legalDocs.cgv.infogerance') }}
          </a>
          , {{ $t('home.legalDocs.our') }}
          <a
            :href="`https://go.skyloud.net/cgv-prestation${locale ? `-${locale}` : ''}`"
            style="text-decoration: underline; font-weight: 700"
            target="_blank"
            rel="noopener noreferrer"
            @click="handleCustomerReading('cgvPrestation')"
          >
            {{ $t('home.legalDocs.cgv.services') }}
          </a>
          , {{ $t('home.legalDocs.our') }}
          <a
            :href="`https://go.skyloud.net/cgs-cloud${locale ? `-${locale}` : ''}`"
            style="text-decoration: underline; font-weight: 700"
            target="_blank"
            rel="noopener noreferrer"
            @click="handleCustomerReading('cgsCloud')"
          >
            {{ $t('home.legalDocs.cgs.cloud') }}
          </a>
          {{ $t('home.legalDocs.andOur') }}
          <a
            :href="`https://go.skyloud.net/cgu-cgs${locale ? `-${locale}` : ''}`"
            style="text-decoration: underline; font-weight: 700"
            target="_blank"
            rel="noopener noreferrer"
            @click="handleCustomerReading('cguCgs')"
          >
            {{ $t('home.legalDocs.cgsu') }}
          </a>
          .
          {{ $t('home.legalDocs.recommandation') }}
        </p>
      </div>
      <span
        class="bg-white-smoke rounded-md px-1.5 py-0.5 text-xs cursor-pointer"
        @click="handleHideCGVBanner"
      >
        <FontAwesomeIcon
          :icon="faClose"
          class="text-primary"
        />
      </span>
    </div>
    <div class="w-full h-full py-4 mx-auto space-y-8 lg:pr-4 3xl:max-w-screen-3xl">
      <div>
        <router-link
          v-if="!subscriptionStatus && isStripeReady"
          to="/settings"
          class="block rounded-lg w-full px-4 py-3 text-xs font-semibold text-center text-error transition-colors bg-[#EB6464] bg-opacity-10"
        >
          <FontAwesomeIcon
            :icon="faCreditCard"
            class="mr-1"
          />
          {{ $t('home.noPaymentMethod.text') }}
          <span class="bg-[#EB6464] bg-opacity-20 rounded px-1 py-0.5 underline">{{ $t('home.noPaymentMethod.here') }}</span>
          .
        </router-link>
        <router-link
          v-if="subscriptionStatus === 'failed'"
          to="/settings"
          class="block rounded-lg w-full px-4 py-3 text-xs font-semibold text-center text-orange-500 bg-orange-500 bg-opacity-10"
        >
          <FontAwesomeIcon
            :icon="faCreditCardFront"
            class="mr-1"
          />
          {{ $t('home.lastPaymentRefused') }}
        </router-link>
      </div>
      <div class="flex flex-col items-stretch h-full gap-2 xl:flex-row">
        <div class="flex flex-col h-full gap-4 xl:w-3/4 bg-white border border-primary border-opacity-20 p-4 rounded-2xl">
          <div class="flex flex-row items-center justify-between">
            <h2 class="font-semibold text-skyloud-dark-blue text-xl">{{ $t('home.title') }}</h2>
            <div class="flex flex-row items-center gap-2">
              <router-link
                v-if="startedTask"
                :to="`/projects/tasks/${startedTask.taskId}`"
                class="flex items-center gap-2 px-4 py-2 text-xs transition-shadow bg-gray-100 rounded shadow hover:opacity-75 focus:shadow-none"
              >
                <span class="relative flex float-left w-3 h-3">
                  <span class="absolute inline-flex w-full h-full bg-green-400 rounded-full opacity-75 animate-ping" />
                  <span class="relative inline-flex w-3 h-3 bg-green-500 rounded-full" />
                </span>
                <span>
                  {{ startedTask.taskId }}
                </span>
              </router-link>
              <div class="flex flex-row ml-2 items-center justify-center text-xs transition-colors duration-150 rounded">
                <SkyTooltip
                  v-if="!isSearchingForTask"
                  persist
                >
                  <template #content>
                    <Shortcut
                      class="text-md"
                      keybind="k"
                      @trigger="handleTaskSearchEvent"
                    />
                  </template>
                  <button
                    type="button"
                    class="px-3 py-2 rounded outline-none appearance-none text-primary hover:bg-gray-100 hover:opacity-75 focus:outline-none"
                    @click="searchTaskModalOpen = true"
                  >
                    <FontAwesomeIcon :icon="faSearch" />
                  </button>
                </SkyTooltip>

                <div
                  v-else
                  class="flex flex-row"
                >
                  <p
                    class="flex flex-row items-center px-3 py-2 leading-none text-white bg-green-400 rounded-l appearance-none cursor-pointer hover:opacity-75"
                    @click="searchTaskModalOpen = true"
                  >
                    <FontAwesomeIcon
                      :icon="faSearch"
                      class="mr-1"
                    />
                    1 {{ $t('home.filters.search') }}
                  </p>
                  <button
                    class="px-2 py-2 text-white bg-green-400 rounded-r outline-none appearance-none cursor-pointer bg-opacity-70 focus:outline-none"
                    type="button"
                    @click="resetCurrentSearch"
                  >
                    <FontAwesomeIcon :icon="faTimes" />
                  </button>
                </div>
              </div>
              <div class="relative text-xs transition-colors duration-150 rounded hover:opacity-75">
                <button
                  type="button"
                  class="px-3 py-2 rounded outline-none appearance-none text-primary hover:bg-gray-100 focus:outline-none"
                  @click="archivedTasksModalOpen = true"
                >
                  <FontAwesomeIcon :icon="faArchive" />
                </button>
              </div>
              <StateFiltersSelect />
              <LabelFiltersSelect />
              <SkyButton
                size="small"
                @click="newTaskModalOpen = true"
              >
                <FontAwesomeIcon
                  :icon="faPlus"
                  class="mr-1"
                />
                {{ $t('home.newTask') }}
              </SkyButton>
            </div>
          </div>
          <TasksDragAndDrop
            class="tasks-kanban"
            @move="handleMoveTask"
          >
            <TasksList
              list-id="todo"
              :watch-scroll="hasMoreTodoTasks"
              class="bg-[#F6F7FF] rounded-md p-2 w-full lg:w-1/3"
              @on-scroll-bottom="fetchTodoTasksNextPagination"
            >
              <template #header>
                <div class="flex gap-1 flex-row items-center justify-between flex-wrap">
                  <div class="flex flex-row items-center gap-1">
                    <FontAwesomeIcon
                      :icon="faInboxIn"
                      class="mr-1 text-error"
                    />
                    {{ $t('home.status.todo') }}
                  </div>
                  <div
                    v-if="isAdminSkyloud"
                    class="flex flex-row items-center gap-2 absolute transform -translate-y-1/2 right-1 top-1/2"
                  >
                    <SkyTooltip
                      v-if="todoTotalEstimatedTime > 0"
                      variant="white-smoke"
                    >
                      <template #content>Temps total estimé</template>
                      <span class="px-2 py-0.5 text-xs leading-5 text-white rounded bg-error">
                        <FontAwesomeIcon :icon="faClock" />
                        {{ formatDurationFromMs(todoTotalEstimatedTime, 'm', 'h') }}
                      </span>
                    </SkyTooltip>

                    <SkyTooltip
                      v-if="todoTotalValidatedTime > 0"
                      variant="white-smoke"
                    >
                      <template #content>Temps total estimé & validé</template>
                      <span class="px-2 py-0.5 text-xs leading-5 text-white rounded bg-error">
                        <FontAwesomeIcon :icon="faCheck" />
                        {{ formatDurationFromMs(todoTotalValidatedTime, 'm', 'h') }}
                      </span>
                    </SkyTooltip>

                    <SkyTooltip
                      v-if="todoWaitingEstimateValidation > 0"
                      variant="white-smoke"
                    >
                      <template #content>Nombre de ticket en attente d'estimation</template>
                      <span class="px-2 py-0.5 text-xs leading-5 text-white rounded bg-error">
                        <FontAwesomeIcon :icon="faPause" />
                        {{ todoWaitingEstimateValidation }}
                      </span>
                    </SkyTooltip>
                  </div>
                  <span
                    v-else
                    v-show="todoEllapsedTime > 0"
                    class="absolute px-2 text-xs leading-5 text-white transform -translate-y-1/2 rounded top-1/2 right-1 bg-error"
                  >
                    {{ formatDurationFromMs(todoEllapsedTime) }}
                  </span>
                </div>
              </template>
              <TaskCard
                v-for="task of todoTasks"
                :key="task._id"
                :task="task"
              />
            </TasksList>
            <TasksList
              list-id="in_progress"
              :watch-scroll="hasMoreInProgressTasks"
              class="bg-[#F6F7FF] rounded-md p-2 w-full lg:w-1/3"
              @on-scroll-bottom="fetchInProgressTasksNextPagination"
            >
              <template #header>
                <div class="flex gap-1 flex-row items-center justify-between flex-wrap">
                  <div class="flex flex-row items-center gap-1">
                    <FontAwesomeIcon
                      :icon="faBolt"
                      class="mr-1 text-warning"
                    />
                    {{ $t('home.status.doing') }}
                  </div>
                  <div
                    v-if="isAdminSkyloud"
                    class="flex flex-row items-center gap-2"
                  >
                    <SkyTooltip
                      v-if="inProgressTotalEstimatedTime > 0"
                      variant="white-smoke"
                    >
                      <template #content>Temps total estimé</template>
                      <span class="px-2 py-0.5 text-xs leading-5 text-white rounded bg-warning">
                        <FontAwesomeIcon :icon="faClock" />
                        {{ formatDurationFromMs(inProgressTotalEstimatedTime, 'm', 'h') }}
                      </span>
                    </SkyTooltip>

                    <SkyTooltip variant="white-smoke">
                      <template
                        v-if="inProgressEffectiveTime > 0"
                        #content
                      >
                        Temps effectif
                      </template>
                      <span class="px-2 py-0.5 text-xs leading-5 text-white rounded bg-warning">
                        <FontAwesomeIcon :icon="faPlay" />
                        {{ formatDurationFromMs(inProgressEffectiveTime, 'm', 'h') }}
                      </span>
                    </SkyTooltip>

                    <SkyTooltip
                      v-if="inProgressPendingEffectiveTime > 0"
                      variant="white-smoke"
                    >
                      <template #content>Temps effectif en attente de validation</template>
                      <span class="px-2 py-0.5 text-xs leading-5 text-white rounded bg-warning">
                        <FontAwesomeIcon :icon="faPause" />
                        {{ formatDurationFromMs(inProgressPendingEffectiveTime, 'm', 'h') }}
                      </span>
                    </SkyTooltip>
                  </div>
                  <span
                    v-else
                    v-show="inProgressEllapsedTime > 0"
                    class="absolute px-2 text-xs leading-5 text-white transform -translate-y-1/2 rounded top-1/2 right-1 bg-warning"
                  >
                    {{ formatDurationFromMs(inProgressEllapsedTime) }}
                  </span>
                </div>
              </template>
              <TaskCard
                v-for="task of inProgressTasks"
                :key="task._id"
                :task="task"
              />
            </TasksList>
            <TasksList
              list-id="done"
              :watch-scroll="hasMoreDoneTasks"
              class="bg-[#F6F7FF] rounded-md p-2 w-full lg:w-1/3"
              @on-scroll-bottom="fetchDoneTasksNextPagination"
            >
              <template #header>
                <div class="flex gap-1 flex-row items-center justify-between flex-wrap">
                  <div class="flex flex-row items-center gap-1">
                    <FontAwesomeIcon
                      :icon="faCheckDouble"
                      class="mr-1 text-success"
                    />
                    {{ $t('home.status.done') }}
                  </div>
                  <div
                    v-if="isAdminSkyloud"
                    class="flex flex-row items-center gap-2 absolute transform -translate-y-1/2 right-1 top-1/2"
                  >
                    <SkyTooltip
                      v-if="doneTotalEstimatedTime > 0"
                      variant="white-smoke"
                    >
                      <template #content>Temps total estimé</template>
                      <span class="px-2 py-0.5 text-xs leading-5 text-white rounded bg-success">
                        <FontAwesomeIcon :icon="faClock" />
                        {{ formatDurationFromMs(doneTotalEstimatedTime, 'm', 'h') }}
                      </span>
                    </SkyTooltip>
                    <SkyTooltip
                      v-if="doneTotalSpentTime > 0"
                      variant="white-smoke"
                    >
                      <template #content>Temps total effectué</template>
                      <span class="px-2 py-0.5 text-xs leading-5 text-white rounded bg-success">
                        <FontAwesomeIcon :icon="faCheck" />
                        {{ formatDurationFromMs(doneTotalSpentTime, 'm', 'h') }}
                      </span>
                    </SkyTooltip>
                  </div>
                  <span
                    v-else
                    v-show="doneEllapsedTime > 0"
                    class="absolute px-2 text-xs leading-5 text-white transform -translate-y-1/2 rounded top-1/2 right-1 bg-success"
                  >
                    {{ formatDurationFromMs(doneEllapsedTime) }}
                  </span>
                </div>
              </template>
              <TaskCard
                v-for="task of doneTasks"
                :key="task._id"
                :task="task"
              />
            </TasksList>
          </TasksDragAndDrop>
        </div>
        <div class="flex-1 rounded-xl overflow-y-auto flex flex-col gap-2">
          <SkyCard class="px-4">
            <template #header>
              <SkyTitle>{{ $t('home.sla.availability') }}</SkyTitle>
            </template>
            <template #options>
              <div class="flex flex-row justify-end items-center w-full">
                <AvailabilityPeriodSelect
                  :icon="faCalendarDays"
                  :options="availabilityPeriods"
                  @input="handleAvailabilityPeriodSelected"
                />
              </div>
            </template>
            <div>
              <AvailabilityWidget />
              <span class="h-[1px] block w-full mx-auto bg-[#D0DAFD] my-4"></span>
            </div>
            <h3 class="text-center font-semibold">SLA Skyloud</h3>
            <div class="flex justify-center items-center mb-3">
              <span class="text-xs text-primary">{{ $t('components.stats.slaPeriods') }}</span>
            </div>
            <AvailabilitySLA />
          </SkyCard>
          <div
            v-for="externalApp of currentApp.externalApps || []"
            :key="externalApp.name"
          >
            <ExternalButtonLink
              :href="externalApp.accessUrl"
              :name="externalApp.name"
              :primary-color="externalApp.primaryColor"
              :secondary-color="externalApp.secondaryColor"
              :logo-url="externalApp.logoUrl"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <NewTaskModal
    :open="newTaskModalOpen"
    @close="handleNewTaskClose"
  />
  <EditTaskModal
    :open="editTaskModalOpen"
    :task-id="taskId"
    @close="handleEditTaskClose"
  />
  <ArchivedTasksListModal
    :open="archivedTasksModalOpen && !taskId"
    @close="archivedTasksModalOpen = false"
  />
  <TaskSearchModal
    :open="searchTaskModalOpen"
    @close="handleTaskSearchClose"
    @search="handleTaskSearch"
  />
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useNotify } from '@/plugins/notify/index.js';
import { formatDurationFromMs } from '@/utils/format.js';
import TaskCard from '@/modules/projects/components/TaskCard.vue';
import TasksList from '@/modules/projects/components/TasksList.vue';
import NewTaskModal from '@/modules/projects/components/NewTaskModal.vue';
import EditTaskModal from '@/modules/projects/components/EditTaskModal.vue';
import ExternalButtonLink from '@/components/effects/ExternalButtonLink.vue';
import TasksDragAndDrop from '@/modules/projects/components/TasksDragAndDrop.vue';
import Shortcut from '@/modules/shared/components/Shortcut.vue';
import LabelFiltersSelect from '@/modules/projects/components/LabelFiltersSelect.vue';
import StateFiltersSelect from '@/modules/projects/components/StateFiltersSelect.vue';
import ArchivedTasksListModal from '@/modules/projects/components/ArchivedTasksListModal.vue';
import AvailabilitySLA from '@/modules/stats/availability/infrastructure/presenters/molecules/AvailabilitySLA.vue';
import { faArchive, faBolt, faCheckDouble, faClose, faInboxIn, faPlus, faScaleBalanced, faSearch, faTimes, faChartLineUp } from '@fortawesome/pro-regular-svg-icons';
import { faCreditCard, faCreditCardFront, faPlay, faPause, faCheck, faClock, faCalendarDays } from '@fortawesome/pro-solid-svg-icons';
import TaskSearchModal from '@/modules/projects/components/TaskSearchModal.vue';
import AvailabilityWidget from '@/modules/stats/availability/infrastructure/presenters/organisms/widget/AvailabilitySuspenseWidget.vue';
import AvailabilityPeriodSelect from '@/modules/stats/availability/infrastructure/presenters/organisms/AvailabilityPeriodSelect.vue';
import get from 'lodash/get';

const store = useStore();
const notify = useNotify();
const route = useRoute();
const router = useRouter();
const { t, locale } = useI18n();

const currentApp = computed(() => store.getters['apps/currentApp']);
const isAdminSkyloud = computed(() => store.getters['auth/isAdminSkyloud']);
const todoTasks = computed(() => store.getters['projects/getTodoTasks']);
const hasMoreTodoTasks = computed(() => store.getters['projects/getHasMoreTodoTasks']);
const hasMoreInProgressTasks = computed(() => store.getters['projects/getHasMoreInProgressTasks']);
const hasMoreDoneTasks = computed(() => store.getters['projects/getHasMoreDoneTasks']);
const inProgressTasks = computed(() => store.getters['projects/getInProgressTasks']);
const doneTasks = computed(() => store.getters['projects/getDoneTasks']);
const todoEllapsedTime = computed(() => store.getters['projects/getTodoEllapsedTime']);
const inProgressEllapsedTime = computed(() => store.getters['projects/getInProgressEllapsedTime']);
const doneEllapsedTime = computed(() => store.getters['projects/getDoneEllapsedTime']);
const tasksStats = computed(() => store.getters['projects/getTasksStats']);
const todoStats = computed(() => get(tasksStats.value, 'todoStats', {}));
const inProgressStats = computed(() => get(tasksStats.value, 'inProgressStats', {}));
const doneStats = computed(() => get(tasksStats.value, 'doneStats', {}));

const todoTotalEstimatedTime = computed(() => get(todoStats.value, 'totalEstimatedTime', 0));
const todoTotalValidatedTime = computed(() => get(todoStats.value, 'totalValidatedTime', 0));
const todoWaitingEstimateValidation = computed(() => get(todoStats.value, 'waitingEstimateValidation', 0));

const inProgressTotalEstimatedTime = computed(() => get(inProgressStats.value, 'totalEstimatedTime', 0));
const inProgressEffectiveTime = computed(() => get(inProgressStats.value, 'effectiveTime', 0));
const inProgressPendingEffectiveTime = computed(() => get(inProgressStats.value, 'pendingEffectiveTime', 0));

const doneTotalEstimatedTime = computed(() => get(doneStats.value, 'totalEstimatedTime', 0));
const doneTotalSpentTime = computed(() => get(doneStats.value, 'totalSpentTime', 0));
const startedTask = computed(() => store.getters['projects/getUserTaskWithStartedWorkLog']);
const subscriptionStatus = computed(() => store.getters['apps/getSubscriptionStatus']);
const isStripeReady = computed(() => store.getters['apps/isStripeReady']);
const taskId = computed(() => route.params.taskId);
const isSearchingForTask = computed(() => {
  const currentSearch = store.getters['projects/getCurrentSearch'];
  return currentSearch && currentSearch.length > 0;
});
const editTaskModalOpen = computed(() => !!taskId.value);
const newTaskModalOpen = ref(false);
const archivedTasksModalOpen = ref(false);
const searchTaskModalOpen = ref(false);
const hideCgvBanner = ref(true);
const hidePricingUpBanner = ref(true);

const availabilityPeriods = [
  { value: 1, label: t('home.sla.availabilityPeriods.currentMonth') },
  { value: 2, label: t('home.sla.availabilityPeriods.seeMore'), to: 'stats' },
];

async function handleMoveTask({ taskId: id, listId }) {
  try {
    await store.dispatch('projects/moveTaskToList', { taskId: id, listId });
  } catch (err) {
    notify.error(t('home.cantMoveTask'));
  }
}

async function fetchTasks() {
  try {
    store.dispatch('projects/watchTasksOpen');
    store.dispatch('chats/watchMessagesOpen');

    if (isAdminSkyloud.value) {
      store.dispatch('projects/fetchTasksStats');
    }

    await store.dispatch('projects/fetchTasks');
    await store.dispatch('projects/fetchDeletedTasks');
  } catch (err) {
    notify.error(err.message);
  }
}

async function fetchLabels() {
  try {
    await store.dispatch('projects/fetchLabels');
  } catch (err) {
    notify.error(err.message);
  }
}

async function fetchMilestones() {
  try {
    await store.dispatch('projects/fetchMilestones');
  } catch (err) {
    notify.error(err.message);
  }
}

function handleNewTaskClose({ taskId: id = undefined } = {}) {
  newTaskModalOpen.value = false;
  if (!id) {
    return;
  }
  nextTick(() => router.push(`/projects/tasks/${id}`));
}

function handleEditTaskClose() {
  router.push('/');
}

async function fetchTodoTasksNextPagination() {
  try {
    await store.dispatch('projects/fetchTodoTasks', { fetchNext: true });
  } catch (err) {
    notify.error(err.message);
  }
}

async function fetchInProgressTasksNextPagination() {
  try {
    await store.dispatch('projects/fetchInProgressTasks', { fetchNext: true });
  } catch (err) {
    notify.error(err.message);
  }
}

async function fetchDoneTasksNextPagination() {
  try {
    await store.dispatch('projects/fetchDoneTasks', { fetchNext: true });
  } catch (err) {
    notify.error(err.message);
  }
}

function handleAvailabilityPeriodSelected({ option, init }) {
  if (!init && option.to) {
    router.push(option.to);
  }
}

function handleTaskSearchEvent() {
  searchTaskModalOpen.value = !searchTaskModalOpen.value;
}

function handleTaskSearchClose(payload) {
  searchTaskModalOpen.value = false;
  if (payload) {
    const { taskSlug } = payload;
    router.push(`/projects/tasks/${taskSlug}`);
  }
}

async function handleTaskSearch(callback) {
  try {
    await store.dispatch('projects/fetchTasks');
    await store.dispatch('projects/fetchDeletedTasks', { ignorePagination: true });
    const result = [...store.getters['projects/getFilteredTasks'], ...store.getters['projects/getFilteredDeletedTasks']];
    callback(result);
  } catch (err) {
    notify.error(err.message);
  }
}

async function handleCustomerReading(document) {
  try {
    const isAlreadyRead = get(currentApp.value, `metadata.${document}`);

    if (isAlreadyRead) return;

    await store.dispatch('apps/updateCGVMetadata', { key: document, value: true });
  } catch (err) {
    notify.error(err.message);
  }
}

async function handleHidePricingUpBanner() {
  try {
    hidePricingUpBanner.value = true;

    const isAlreadyHidden = get(currentApp.value, `metadata.pricingUp`);

    if (isAlreadyHidden) return;

    await store.dispatch('apps/updateCGVMetadata', { key: 'pricingUp', value: true });
  } catch (err) {
    notify.error(err.message);
  }
}

async function handleHideCGVBanner() {
  try {
    hideCgvBanner.value = true;

    const isAlreadyHidden = get(currentApp.value, `metadata.cgvHideBanner`);

    if (isAlreadyHidden) return;

    await store.dispatch('apps/updateCGVMetadata', { key: 'cgvHideBanner', value: true });
  } catch (err) {
    notify.error(err.message);
  }
}

function resetCurrentSearch() {
  store.dispatch('projects/resetCurrentSearch', undefined);
}

onMounted(() => {
  fetchTasks();
  fetchLabels();
  fetchMilestones();
});

onBeforeUnmount(() => {
  store.dispatch('projects/watchTasksClose');
  store.dispatch('chats/watchMessagesClose');
});

watch(
  () => currentApp.value.metadata,
  () => {
    hideCgvBanner.value = !!get(currentApp.value, `metadata.cgvHideBanner`);
  },
  { deep: true, immediate: true },
);

watch(
  () => currentApp.value.metadata,
  () => {
    hidePricingUpBanner.value = !!get(currentApp.value, `metadata.pricingUp`);
  },
  { deep: true, immediate: true },
);
</script>

<style lang="scss" scoped>
.tasks-kanban {
  @apply gap-4;
  height: calc(100% - 48px);
}
</style>
